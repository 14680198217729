import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"
import ExpansionPanel from "@trades-org/waas-component-library/dist/components/molecules/expansion-panel/expansion-panel"
import { ChevronUpIcon } from "@heroicons/react/24/solid"
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon"
import { FC } from "react"
import { SectionFaqsProps } from "../../../../types/bright-hive/faqs.types"

const Faqs: FC<SectionFaqsProps> = ({ title, description, faqs }): JSX.Element | null => {
    if (!faqs) return null
    return (
        <div
            id={"faq"}
            className={
                "mx-auto max-w-7xl space-y-10 md:space-y-0 md:flex md:flex-row md:justify-between pt-10 px-4 sm:px-6 lg:px-14 md:py-36"
            }>
            <TextGroup
                wrapperClassNames={"max-w-[310px] space-y-3 md:space-y-7"}
                title={title}
                titleClassNames={"text-left text-4xl max-w-[300px] tracking-wide"}
                description={description}
                descriptionClassNames={"text-left text-base md:text-sm font-light md:leading-relaxed text-[#2F3945]"}
            />
            <div className={"flex flex-col md:pt-20 divide-y divide-y-gray-200"}>
                {faqs.map(({ question, answer }, index) => {
                    return (
                        <ExpansionPanel
                            key={`faq-item-${index + 1}`}
                            title={question}
                            wrapperClassNames={"md:w-[400px] lg:w-[600px] xl:w-[780px] py-3"}
                            titleClassNames={"text-base tracking-wide font-medium tracking-wide"}
                            iconClassNames={"text-blue-600"}
                            expanderClassNames={"py-3"}
                            textClassNames={"text-sm font-light leading-relaxed tracking-wide"}
                            icon={(isOpened) =>
                                isOpened ? (
                                    <ChevronUpIcon width={16} height={14} />
                                ) : (
                                    <ChevronDownIcon width={16} height={14} />
                                )
                            }
                            panelContent={answer}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default Faqs
