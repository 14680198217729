import { FC } from "react"
import { Feature } from "./components"
import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"
import { StyledRequestForm } from "../../common"
import { SectionHeroProps } from "../../../../types/bright-hive/hero.types"

const Hero: FC<SectionHeroProps> = ({
    slogan,
    uspDescription,
    uspTitle,
    websiteFeatureText,
    requestFormInputs,
    requestFormHandleSubmit,
    staticImageGroupNode,
}): JSX.Element => {
    return (
        <div className={"max-w-8xl mx-auto overflow-hidden"}>
            <div
                id={"hero"}
                className={
                    "relative bg-[#F3F6F9] md:rounded-xl pt-7 md:mx-6 2xl:mx-12 pb-60 md:py-36 space-y-7 md:space-y-10"
                }>
                <TextGroup
                    wrapperClassNames={"md:max-w-lg mx-auto items-left px-5 md:px-0 md:items-center"}
                    slogan={slogan}
                    sloganClassNames={"text-base text-blue-600 md:text-2xl leading-7"}
                    title={uspTitle}
                    titleTextType={"h2"}
                    titleClassNames={"max-w-lg md:mt-2 text-left md:text-center"}
                    description={uspDescription}
                    descriptionClassNames={"max-w-md leading-6 font-normal text-left md:text-center text-sm md:mt-6"}
                />
                <StyledRequestForm formInputs={requestFormInputs} handleSubmit={requestFormHandleSubmit} />
                <Feature featureText={websiteFeatureText} />
                <div>{staticImageGroupNode}</div>
            </div>
        </div>
    )
}
export default Hero
