import { graphql, HeadFC, PageProps } from "gatsby"
import HomeHelper from "../helpers/home-helper"
import { aggregate } from "../../../utils/aggregate"
import { propsMap } from "../../../utils/website-dto/bright-hive/props-map"
import { configurationMap } from "../../../utils/website-dto/bright-hive/configuration-map"
import { WebsitePayload } from "../../../utils/website-dto/website-payload"

const IndexPage = ({
    data: {
        api: { website, business, user },
    },
    ...rest
}: PageProps<{
    api: WebsitePayload
}>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const requiredData = aggregate(propsMap, configurationMap, {
        ...website,
        ...business,
        phone: user.phone,
    })
    return <HomeHelper {...requiredData} cNavigate={rest.navigate} />
}
export const Head: HeadFC = () => <title>Home Page</title>

export default IndexPage

export const query = graphql`
    query ($businessId: String!, $userId: String!) {
        api {
            website(where: { businessId: $businessId }) {
                ...WebsiteInfo
            }
            business(where: { id: $businessId }) {
                ...BusinessInfo
            }
            user(where: { id: $userId }) {
                ...UserInfo
            }
        }
    }
`
