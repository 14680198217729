import { FC } from "react"
import Header from "../../../components/bright-hive/sections/header/header"
import Hero from "../../../components/bright-hive/sections/hero"
import Footer from "../../../components/bright-hive/sections/footer"
import TradeCategories from "../../../components/bright-hive/sections/trade-categories"
import HighlightedServices from "../../../components/bright-hive/sections/highlighted-services"
import OurProcess from "../../../components/bright-hive/sections/our-process"
import Guarantees from "../../../components/bright-hive/sections/guarantees"
import RequestQuote from "../../../components/bright-hive/sections/request-quote"
import AboutUs from "../../../components/bright-hive/sections/about-us"
import Cta from "../../../components/bright-hive/sections/cta"
import ServiceArea from "../../../components/bright-hive/sections/service-area"
import Faqs from "../../../components/bright-hive/sections/faqs"
import Testimonials from "../../../components/bright-hive/sections/testimonials"
import { BrightHiveWebsiteProps } from "../../../types/bright-hive/website/website.types"

export const HomeHelper: FC<BrightHiveWebsiteProps> = ({
    hero,
    aboutUs,
    faqs,
    guarantees,
    testimonials,
    tradeCategories,
    highlightedServices,
    ourProcess,
    serviceArea,
    cta,
    requestQuote,
    header,
    footer,
    cNavigate,
    cLocation,
}) => {
    return (
        <>
            <header>
                <Header {...header} navigate={cNavigate} path={cLocation?.pathname} />
            </header>
            <main>
                <Hero {...hero} />
                <TradeCategories {...tradeCategories} />
                <HighlightedServices {...highlightedServices} />
                <OurProcess {...ourProcess} />
                <Testimonials {...testimonials} />
                <Guarantees {...guarantees} />
                <RequestQuote {...requestQuote} path={cLocation?.pathname} navigate={cNavigate} />
                <Faqs {...faqs} />
                <AboutUs {...aboutUs} navigate={cNavigate} path={cLocation?.pathname} />
                <ServiceArea {...serviceArea} />
                <Cta {...cta} />
            </main>
            <footer>
                <Footer {...footer} path={cLocation?.pathname} />
            </footer>
        </>
    )
}

export default HomeHelper
