import { FC } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Virtual } from "swiper"
import { NavButtons, TestimonialCard } from "./components"
import { SectionTestimonialsProps } from "../../../../types/bright-hive/testimonials.types"

const Testimonials: FC<SectionTestimonialsProps> = ({
    testimonials,
    testimonialUserFallbackImage,
}): JSX.Element | null => {
    if (!testimonials) return null
    return (
        <div
            className={
                "relative mx-auto max-w-7xl space-y-10 md:space-y-0 md:flex md:flex-row md:items-center md:justify-between pt-10 px-4 sm:px-6 lg:px-14"
            }>
            <Swiper
                virtual={true}
                navigation={{
                    prevEl: "#prev_testimonial_slide",
                    nextEl: "#next_testimonial_slide",
                }}
                slidesPerView={1}
                centeredSlides={true}
                modules={[Virtual, Navigation]}
                className="testimonialBrightHiveSwiper">
                {testimonials.map(({ title, description, author }, index) => {
                    const userImage = author?.photoUrl ? (
                        <img src={author.photoUrl} alt={`testimonial-${index + 1}`} />
                    ) : (
                        testimonialUserFallbackImage
                    )

                    return (
                        <SwiperSlide key={`testimonial-slide-${index + 1}`} className={"flex flex-col md:flex-row"}>
                            <TestimonialCard
                                userPhoto={userImage}
                                testimonial={title}
                                description={description}
                                authorName={author?.name}
                            />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <NavButtons />
        </div>
    )
}
export default Testimonials
