import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"
import BaseButton from "@trades-org/waas-component-library/dist/components/atoms/base-button/base-button"
import { FC } from "react"
import { SectionRequestQuoteProps } from "../../../../types/bright-hive/request-quote.types"

const RequestQuote: FC<SectionRequestQuoteProps> = ({
    description,
    title,
    buttonTitle,
    buttonHandleClick,
    path,
    navigate,
}) => {
    return (
        <div className={"bg-[#1D1D1D]"}>
            <div className={"mx-auto max-w-7xl space-y-10 md:space-y-0 py-10 px-4 sm:px-6 lg:px-14 md:py-16"}>
                <div className={"flex flex-col md:flex-row justify-between items-center space-y-5 md:space-y-0"}>
                    <TextGroup
                        wrapperClassNames={"text-white max-w-sm md:max-w-xl space-y-3"}
                        title={title}
                        titleTextType={"h4"}
                        titleClassNames={"text-xl"}
                        description={description}
                        descriptionClassNames={"text-left text-sm font-extralight"}
                    />
                    <div className={"flex justify-center w-full md:block md:w-auto"}>
                        <BaseButton
                            onClick={() => buttonHandleClick(navigate, path)}
                            className={"max-w-sm w-full md:w-auto rounded-md text-xs text-white bg-blue-700 p-4 px-8"}>
                            {buttonTitle}
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestQuote
