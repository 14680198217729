import { FC } from "react"
import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"
import BaseCard from "@trades-org/waas-component-library/dist/components/organisms/base-card/base-card"
import { SectionOurProcessesProps } from "../../../../types/bright-hive/our-process.types"

const OurProcess: FC<SectionOurProcessesProps> = ({
    title,
    description,
    steps,
    stepIconFallbackNode,
    sectionImageNode,
}): JSX.Element | null => {
    if (!steps) return null
    return (
        <div
            id={"our-process"}
            className={"mx-auto max-w-7xl space-y-10 md:space-y-0 py-10 px-4 sm:px-6 lg:px-14 md:py-16"}>
            <div className={"flex flex-col md:flex-row justify-between"}>
                <div className={"flex flex-col md:block space-y-7"}>
                    <TextGroup
                        wrapperClassNames={"max-w-xl space-y-2"}
                        title={title}
                        titleClassNames={"text-4xl text-left leading-relaxed"}
                        description={description}
                        descriptionClassNames={"max-w-[420px] text-left text-sm font-light leading-relaxed"}
                    />
                    <div className={"md:space-y-3"}>
                        {steps?.map(({ title, description }, index) => {
                            return (
                                <BaseCard
                                    key={`process-step-${index + 1}`}
                                    wrapperClassNames={
                                        "md:flex-row md:space-x-5 max-w-[620px] p-4 rounded-lg hover:shadow-lg hover:outline-none"
                                    }
                                    icon={stepIconFallbackNode}
                                    textGroupProps={{
                                        wrapperClassNames: "py-3 max-w-xs xl:max-w-[500px] space-y-3",
                                        title,
                                        description,
                                        titleClassNames: "text-left text-lg md:text-base leading-tight",
                                        titleTextType: "h4",
                                        descriptionClassNames:
                                            "text-left text-normal md:text-sm font-light leading-relaxed text-[#2F3945]",
                                    }}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className={"pt-14"}>{sectionImageNode}</div>
            </div>
        </div>
    )
}

export default OurProcess
