import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"
import Typography from "@trades-org/waas-component-library/dist/components/atoms/typography/typography"
import { ServiceImage } from "./components/service-image"
import { FC } from "react"
import { SectionHighlightedServicesProps } from "../../../../types/bright-hive/highlighted-services.types"

const HighlightedServices: FC<SectionHighlightedServicesProps> = ({ services, title }): JSX.Element | null => {
    if (!services) return null
    return (
        <div className={"bg-[#F3F6F9]"} id={"services"}>
            <div className={"mx-auto max-w-7xl space-y-10 md:space-y-0 py-10 px-4 sm:px-6 lg:px-14 md:py-24"}>
                <div className={"flex md:justify-center md:pb-16"}>
                    <Typography textType={"h3"}>{title}</Typography>
                </div>
                <div className={"flex flex-col space-y-20"}>
                    {services?.map(({ slogan, title, description }, index) => {
                        return (
                            <div
                                id={title.trim().toLowerCase().split(" ").join("-")}
                                className={`flex flex-col space-y-5 md:space-y-0 md:space-x-5 xl:space-x-0 ${
                                    index % 2 ? "md:flex-row" : "md:flex-row-reverse"
                                } md:justify-between`}
                                key={`highlighted-service-${index + 1}`}>
                                <ServiceImage serviceIndex={index} />
                                <TextGroup
                                    wrapperClassNames={"max-w-sm lg:max-w-md space-y-4 text-[#2F3945]"}
                                    slogan={slogan?.toUpperCase()}
                                    sloganClassNames={"text-xs"}
                                    title={title}
                                    titleClassNames={"text-2xl text-left leading-relaxed"}
                                    description={description}
                                    descriptionClassNames={"text-sm text-left font-light leading-relaxed"}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default HighlightedServices
