import { FC } from "react"
import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"
import { GoogleMapComponent, LinkGroup } from "@trades-org/waas-component-library"
import { EnvelopeIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/24/solid"
import Typography from "@trades-org/waas-component-library/dist/components/atoms/typography/typography"
import useWindowWidth from "../../../../utils"
import { SectionServiceAreaProps } from "../../../../types/bright-hive/service-area.types"
import SocialLink from "@trades-org/waas-component-library/dist/components/molecules/social-link/social-link"

const ServiceArea: FC<SectionServiceAreaProps> = ({
    serviceArea,
    email,
    phoneNumber,
    coordinatesCenter,
    linksTitle,
    links,
    title,
    description,
}) => {
    const windowWidth = useWindowWidth()
    const showMap = !!coordinatesCenter?.lng && !!coordinatesCenter?.lat
    const showSocialLinks = !!links?.length
    return (
        <div className={"bg-[#005AE7]"} id={"service-area"}>
            <div className={"mx-auto max-w-7xl space-y-10 md:space-y-0 px-4 sm:px-6 lg:px-14 pt-5 md:pt-10"}>
                <div className={"space-y-6 md:space-y-16 md:py-10 lg:mx-20"}>
                    <TextGroup
                        wrapperClassNames={"text-white flex items-center justify-center"}
                        title={title}
                        description={description}
                    />
                    <div
                        className={`flex flex-col space-y-6 md:space-y-0 justify-center md:flex-row md:justify-between text-white py-10 ${
                            showMap || showSocialLinks ? "border-b border-b-[#156EFA]" : ""
                        }`}>
                        {serviceArea && (
                            <SocialLink
                                href={`https://maps.google.com/?q=${serviceArea}`}
                                target={"_blank"}
                                rel={"noopener noreferrer"}
                                icon={
                                    <>
                                        <MapPinIcon
                                            className={"mx-auto text-[#D6FF31] w-8 h-8"}
                                            width={24}
                                            height={24}
                                        />
                                        <Typography className={"text-lg"} textType={"p"}>
                                            {serviceArea}
                                        </Typography>
                                    </>
                                }
                                classNames={"flex flex-col items-center space-y-4 md:w-[300px]"}
                            />
                        )}
                        {phoneNumber && (
                            <SocialLink
                                href={`tel:${phoneNumber}`}
                                icon={
                                    <>
                                        <PhoneIcon
                                            className={"mx-auto text-[#D6FF31] w-8 h-8"}
                                            width={24}
                                            height={24}
                                        />
                                        <Typography className={"text-lg"} textType={"p"}>
                                            {phoneNumber}
                                        </Typography>
                                    </>
                                }
                                classNames={"flex flex-col items-center space-y-4 md:w-[300px]"}
                            />
                        )}
                        {email && (
                            <SocialLink
                                href={`mailto:${email}`}
                                icon={
                                    <>
                                        <EnvelopeIcon
                                            className={"mx-auto text-[#D6FF31] w-8 h-8"}
                                            width={24}
                                            height={24}
                                        />
                                        <Typography className={"text-lg"} textType={"p"}>
                                            {email}
                                        </Typography>
                                    </>
                                }
                                classNames={"flex flex-col items-center space-y-4 md:w-[300px]"}
                            />
                        )}
                    </div>
                    {showSocialLinks && (
                        <LinkGroup
                            wrapperClassNames={"flex flex-col text-white justify-center items-center space-y-7"}
                            heading={linksTitle?.toUpperCase()}
                            headingClassNames={"text-sm font-light"}
                            links={links}
                            linksWrapperClassNames={"space-x-3.5"}
                        />
                    )}
                </div>
            </div>
            {showMap && (
                <div className={"pt-20 md:pt-0 md:pb-20"}>
                    <div className={"flex mx-auto max-w-md md:max-w-7xl md:px-14 w-full h-[350px]"}>
                        <GoogleMapComponent
                            center={coordinatesCenter?.lat && coordinatesCenter?.lng ? coordinatesCenter : undefined}
                            containerStyle={{
                                height: "350px",
                                borderRadius: `${windowWidth && windowWidth < 768 ? "" : "25px"}`,
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default ServiceArea
