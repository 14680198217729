import { StaticImage } from "gatsby-plugin-image"
import Typography from "@trades-org/waas-component-library/dist/components/atoms/typography/typography"
import { FC } from "react"

export interface GuaranteeCardProps {
    title?: string
    description?: string
}

export const GuaranteeCard: FC<GuaranteeCardProps> = ({ title = "", description = "" }): JSX.Element => {
    return (
        <div className={"max-w-[300px] space-y-5"}>
            <div className={"space-x-5"}>
                <div className={"h-[45px] w-[45px] inline-flex items-center justify-center bg-black p-2 rounded-lg"}>
                    <StaticImage
                        width={20}
                        height={14}
                        src={"../../../../../images/bright-hive/guarantees/Group 25.svg"}
                        className={"object-contain"}
                        alt={"trade-category-placeholder"}
                    />
                </div>
                <Typography textType={"p"} className={"font-semibold"}>
                    {title}
                </Typography>
            </div>
            <Typography textType={"p"} className={"text-sm md:text-[13px] leading-relaxed text-left"}>
                {description}
            </Typography>
        </div>
    )
}
