import { FC } from "react"

export interface FeatureProps {
    featureText?: string
}

export const Feature: FC<FeatureProps> = ({ featureText }): JSX.Element | null => {
    if (!featureText) return null
    return (
        <div className={"flex items-left px-5 md:px-0 md:justify-center"}>
            <p className={"text-[#7E8A97] text-xs max-w-xl text-left md:text-center"}>
                {featureText} {/* todo Use this when payments are going to be implemented */}
                {/* <Link*/}
                {/*    classNames={"text-blue-600 underline hover:opacity-70 cursor-pointer whitespace-nowrap"}*/}
                {/*    onClick={() => {*/}
                {/*        console.log("Get a Project Loan")*/}
                {/*    }}>*/}
                {/*    Get a Project Loan*/}
                {/* </Link>{" "}*/}
                {/* and{" "}*/}
                {/* <Link*/}
                {/*    classNames={"text-blue-600 underline hover:opacity-70 cursor-pointer whitespace-nowrap"}*/}
                {/*    onClick={() => {*/}
                {/*        console.log("Get a Project Loan")*/}
                {/*    }}>*/}
                {/*    Make a Payment*/}
                {/* </Link>*/}.
            </p>
        </div>
    )
}
