import { FC } from "react"
import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"
import BaseCard from "@trades-org/waas-component-library/dist/components/organisms/base-card/base-card"
import BaseButton from "@trades-org/waas-component-library/dist/components/atoms/base-button/base-button"
import { SectionAboutUsProps } from "../../../../types/bright-hive/about-us.types"

const AboutUs: FC<SectionAboutUsProps> = ({
    title,
    description,
    features,
    featureImageFallbackNode,
    sectionButtonTitle,
    sectionButtonHandleClick,
    sectionImageNode,
    path,
    navigate,
}) => {
    return (
        <div className={"mx-auto max-w-7xl space-y-10 md:space-y-0 py-10 px-4 sm:px-6 lg:px-14 md:py-16"}>
            <div className={"flex flex-col-reverse md:flex-row justify-between"}>
                <div className={"flex flex-col md:block space-y-7 md:pt-14"}>
                    <TextGroup
                        wrapperClassNames={"max-w-xl pt-5 md:pt-0 space-y-2"}
                        title={title}
                        titleClassNames={"text-4xl text-left leading-relaxed"}
                        description={description}
                        descriptionClassNames={"max-w-[420px] text-left text-sm font-light leading-relaxed"}
                    />
                    <div className={"md:space-y-3"}>
                        {features?.map(({ title = "", description = "" }, index) => {
                            return (
                                <BaseCard
                                    key={`testimonial-${index + 1}`}
                                    wrapperClassNames={"flex-row space-x-5 max-w-[620px] p-4"}
                                    icon={featureImageFallbackNode}
                                    textGroupProps={{
                                        wrapperClassNames: "py-3 max-w-xs xl:max-w-[450px] space-y-3",
                                        title: title,
                                        description: description,
                                        titleClassNames: "text-left text-lg md:text-base leading-tight",
                                        titleTextType: "h4",
                                        descriptionClassNames:
                                            "text-left text-normal md:text-sm font-light leading-relaxed text-[#2F3945]",
                                    }}
                                />
                            )
                        })}
                        <div className={"flex justify-center w-full md:block md:w-auto"}>
                            <BaseButton
                                onClick={() => sectionButtonHandleClick(navigate, path)}
                                className={
                                    "max-w-sm w-full md:w-auto rounded-md text-xs text-white bg-blue-700 p-4 px-8"
                                }>
                                {sectionButtonTitle}
                            </BaseButton>
                        </div>
                    </div>
                </div>
                {sectionImageNode}
            </div>
        </div>
    )
}

export default AboutUs
