import { FC } from "react"
import { GuaranteeCard } from "./components"
import { SectionGuaranteesProps } from "../../../../types/bright-hive/guarantees.types"
import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"

const Guarantees: FC<SectionGuaranteesProps> = ({ guarantees, title, description }): JSX.Element | null => {
    if (!guarantees) return null
    return (
        <div className={"mx-auto max-w-7xl space-y-10 md:space-y-0 py-10 px-4 sm:px-6 lg:px-14 md:py-16 lg:pb-28"}>
            <div className={"flex justify-center items-center md:pb-16"}>
                <TextGroup
                    wrapperClassNames={"max-w-md md:items-center space-y-3 md:space-y-5"}
                    title={title}
                    titleTextType={"h3"}
                    titleClassNames={"items-left md:text-center"}
                    description={description}
                    descriptionClassNames={"text-left text-sm md:text-center leading-loose"}
                />
            </div>
            <div className={"grid md:grid-cols-[300px_300px] md:justify-center lg:grid-cols-3 gap-y-7 md:gap-10"}>
                {guarantees?.map(({ title = "", description = "" }, index) => (
                    <GuaranteeCard key={`guarantee-description-${index + 1}`} title={title} description={description} />
                ))}
            </div>
        </div>
    )
}

export default Guarantees
