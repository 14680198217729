import { FC } from "react"
import { StaticImage } from "gatsby-plugin-image"

export interface ServiceImageProps {
    serviceIndex: number
}

export const ServiceImage: FC<ServiceImageProps> = ({ serviceIndex }) => {
    // https://www.gatsbyjs.com/docs/how-to/images-and-media/using-gatsby-plugin-image/#static-images
    // static image paths
    const image1 = "../../../../../images/bright-hive/highlighted-services/image-20.webp"
    const image2 = "../../../../../images/bright-hive/highlighted-services/image-21.webp"

    return serviceIndex % 2 ? (
        <StaticImage
            className={"w-full md:w-[550px] md:h-[350px] rounded-xl"}
            src={image1}
            width={650}
            height={440}
            alt={`highlighted-service-image-${serviceIndex + 1}`}
        />
    ) : (
        <StaticImage
            className={"w-full md:w-[550px] md:h-[350px] rounded-xl"}
            src={image2}
            width={450}
            height={300}
            alt={`highlighted-service-image-${serviceIndex + 1}`}
        />
    )
}
