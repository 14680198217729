import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"
import { StaticImageGroup, StyledRequestForm } from "../../common"
import { FC } from "react"
import { SectionCtaProps } from "../../../../types/bright-hive/cta.types"

const Cta: FC<SectionCtaProps> = ({ ctaDescription, ctaTitle, requestFormHandleSubmit, requestFormInputs }) => {
    return (
        <div className={"max-w-8xl mx-auto overflow-hidden"}>
            <div className={"relative md:rounded-xl pt-7 md:mx-6 2xl:mx-12 pb-60 md:py-36 space-y-7 md:space-y-10"}>
                <TextGroup
                    wrapperClassNames={"md:max-w-lg mx-auto items-left px-5 md:px-0 md:items-center"}
                    title={ctaTitle}
                    titleTextType={"h2"}
                    titleClassNames={"max-w-lg md:mt-2 text-left md:text-center"}
                    description={ctaDescription}
                    descriptionClassNames={"max-w-md leading-6 font-normal text-left md:text-center text-sm md:mt-6"}
                />
                <StyledRequestForm formInputs={requestFormInputs} handleSubmit={requestFormHandleSubmit} />
                <StaticImageGroup stylesType={"cta"} />
            </div>
        </div>
    )
}

export default Cta
